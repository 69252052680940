import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../general/Select';
import InputText from '../../../../../general/InputText';
import {COLUMN_KEY, OPERATION_KEY, VALUE_KEY, INPUTS_KEY, TYPES} from '../../ReportEditor';
import {Button} from 'antd';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {ReportDefinition, ReportOperation, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans} from '@lingui/macro';

/**
 * @fero
 */

class ReportDefinitionInput extends React.PureComponent {
    static propTypes = {
        before: PropTypes.node,
        hasMargin: PropTypes.bool,
        report: ReportDefinition.isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        onDelete: PropTypes.func,
    };

    render() {
        const {navigation, report, operations, columns, updateReport, onDelete, before} = this.props;
        const def = getPartByObjectNavigation(report, navigation);
        const currentTypeKey = getDefinitionType(def);
        switch (currentTypeKey) 
        {
            case COLUMN_KEY:
                return <ColumnInput
                    key="column"
                    updateReport={updateReport}
                    columns={columns}
                    navigation={navigation}
                    report={report}
                    onDelete={onDelete}
                    before={before}
                />;
            case OPERATION_KEY:
                return <OperationInput
                    key="operation"
                    updateReport={updateReport}
                    navigation={navigation}
                    operations={operations}
                    columns={columns}
                    report={report}
                    onDelete={onDelete}
                    before={before}
                    hasMargin={true}
                />;
            case VALUE_KEY:
            default:
                return <ValueInput
                    key="value"
                    updateReport={updateReport}
                    navigation={navigation}
                    report={report}
                    columns={columns}
                    onDelete={onDelete}
                    before={before}
                />;
        }
    }

}

export default ReportDefinitionInput;

class TypeSelector extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        updateReport: PropTypes.func.isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    changeType = (newTypeKey) => {
        const {updateReport, navigation} = this.props;
        let def = undefined;
        switch (newTypeKey) {
            case COLUMN_KEY:
                def = {[COLUMN_KEY]: null};
                break;
            case OPERATION_KEY:
                def = {[OPERATION_KEY]: null};
                break;
            case VALUE_KEY:
                def = {[VALUE_KEY]: null};
                break;
            default:
                def = undefined;
        }
        const updateDef = {$set: def};
        updateReport(updateDef, navigation);
    };

    render() {
        const {report, navigation} = this.props;
        const def = getPartByObjectNavigation(report, navigation);
        const currentTypeKey = getDefinitionType(def);
        return <Select
            key="type"
            isSearchable={false}
            className="report-type-input d-inline-block my-1"
            onChange={this.changeType}
            options={Object.values(TYPES).map(type => {
                return {
                    label: type.label,
                    value: type.key,
                }
            })}
            value={currentTypeKey}
        />
    }
}
;

class ColumnInput extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        onDelete: PropTypes.func,
        before: PropTypes.node,
    };

    setColumn = (column) => {
        const {updateReport, navigation} = this.props;
        const updateDef = {
            $set: {[COLUMN_KEY]: column,}
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {navigation, report, columns, updateReport, onDelete, before} = this.props;
        const def = getPartByObjectNavigation(report, navigation);
        const currentValue = getDefinitionValue(def);
        return <div className="d-flex flex-wrap align-items-center">
            {before}
            <TypeSelector
                report={report}
                navigation={navigation}
                updateReport={updateReport}
            />
            <Select
                key="column"
                className="report-value-input d-inline-block my-1"
                onChange={this.setColumn}
                options={columns.map(column => {
                    return {
                        label: column.label,
                        value: column.name,
                    }
                })}
                value={currentValue}
                required={true}
            />
            {onDelete != null ? <Button onClick={onDelete} type="danger" icon="delete"/> : null}
        </div>
    }
}

class OperationInput extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        onDelete: PropTypes.func,
        before: PropTypes.node,
    };

    setOperation = (operation) => {
        const {updateReport, navigation, operations} = this.props;
        let inputs = [];
        const arity = getOperationArityOrMinArity(operation, operations);
        for (let i = 0; i < arity; i++) {
            inputs.push({[COLUMN_KEY]: null});
        }
        const updateDef = {
            $set: {
                [OPERATION_KEY]: operation,
                [INPUTS_KEY]: inputs,
            }
        };
        updateReport(updateDef, navigation);
    };

    addInput = () => {
        const {updateReport, navigation} = this.props;
        const inputNavigation = navigation.slice(0);
        inputNavigation.push(INPUTS_KEY);
        const updateDef = {
            $push: [{
                [COLUMN_KEY]: null,
            }]
        };
        updateReport(updateDef, inputNavigation);
    };

    removeInput = (index) => {
        const {navigation, updateReport} = this.props;
        const inputNavigation = navigation.slice(0);
        inputNavigation.push(INPUTS_KEY);
        const updateDef = {
            $splice: [[index, 1]]
        };
        updateReport(updateDef, inputNavigation);
    };

    render() {
        const {navigation, report, operations, columns, updateReport, onDelete, before, hasMargin} = this.props;
        const def = getPartByObjectNavigation(report, navigation);
        const currentValue = getDefinitionValue(def);
        // const arity = getOperationArity(currentValue, operations);
        // let operationInputs = [];
        const inputs = def.inputs != null ? def.inputs : [];
        const hasInputs = def.inputs != null && def.inputs.length != null && def.inputs.length > 0;
        const operationName = def != null ? def.operation : null;
        const minArity = getOperationMinArity(operationName, operations);
        const inputNodes = inputs.map((input, index) => {
            const newNavigation = navigation.slice(0);
            newNavigation.push(INPUTS_KEY, (index + ''));
            return <div key={index}>
                <ReportDefinitionInput
                    updateReport={updateReport}
                    columns={columns}
                    navigation={newNavigation}
                    operations={operations}
                    report={report}
                    onDelete={minArity != null && minArity < inputs.length ? () => {this.removeInput(index)} : undefined}
                />
            </div>;
        });

        return <div>
            <div className="d-flex flex-wrap align-items-center">
                {before}
                <TypeSelector
                    report={report}
                    navigation={navigation}
                    updateReport={updateReport}
                />
                <Select
                    key="select"
                    className="report-value-input d-inline-block my-1"
                    onChange={this.setOperation}
                    options={operations.map(operation => {
                        return {
                            label: operation.label,
                            value: operation.name,
                        }
                    })}
                    value={currentValue}
                    required={true}
                />
                {onDelete != null ?
                    <Button onClick={onDelete} type="danger" icon="delete"/> :
                    null
                }
            </div>
            <div style={hasMargin == true ? {marginLeft: '4rem'} : {}}>
                {hasInputs ? inputNodes : null}
                {minArity != null ?
                    <Button
                        onClick={this.addInput}
                    >
                        <Trans>Pridať</Trans>
                    </Button> : null
                }
            </div>
        </div>;
    }
}

class ValueInput extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        onDelete: PropTypes.func,
        before: PropTypes.node,
    };

    setValue = (value) => {
        const {updateReport, navigation} = this.props;
        const updateDef = {
            $set: {[VALUE_KEY]: value}
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {navigation, report, columns, updateReport, onDelete, before} = this.props;
        const def = getPartByObjectNavigation(report, navigation);
        const currentValue = getDefinitionValue(def);
        const parentDef = navigation.length > 2 ? getPartByObjectNavigation(report, navigation.slice(0, navigation.length-2)) : null;
        const isEqualComp = parentDef != null && (parentDef.operation == '=' || parentDef.operation == '!=');
        const inputs = (isEqualComp && parentDef.inputs != null && parentDef.inputs.length == 2) ? parentDef.inputs : null;
        const colName = inputs != null ? (inputs[0].col != null ? inputs[0].col : inputs[1].col) : null;
        const colDef = colName != null ? columns.find(col => col.name == colName) : null;
        const colVals = colDef != null ? colDef.values : null;
        
        return <div className="d-flex flex-wrap align-items-center">
            {before}
            <TypeSelector
                report={report}
                navigation={navigation}
                updateReport={updateReport}
            />
            {colVals != null && colVals.length > 0 ? 
                <Select
                    key="select"
                    className="report-value-input d-inline-block my-1"
                    onChange={this.setValue}
                    options={colVals.map(tmp => {
                        return {
                            label: tmp,
                            value: tmp,
                        }
                    })}
                    value={currentValue}
                    required={true}
                /> :
                <div className="my-1 report-value-input" key="value">
                    <InputText
                        onChange={this.setValue}
                        value={currentValue}
                        required={true}
                    />
                </div>
            }
            {onDelete != null ? <Button onClick={onDelete} type="danger" icon="delete"/> : null}
        </div>
    }
}

const getDefinitionType = (def) => {
    let type = COLUMN_KEY;
    if (def != null) {
        const defAttributeNames = Object.keys(def);
        if (defAttributeNames.includes(COLUMN_KEY)) {
            type = COLUMN_KEY;
        } else if (defAttributeNames.includes(OPERATION_KEY)) {
            type = OPERATION_KEY;
        } else if (defAttributeNames.includes(VALUE_KEY)) {
            type = VALUE_KEY;
        }
    }
    return type;
};

const getDefinitionValue = (def) => {
    let value = null;
    if (def != null) {
        const defAttributeNames = Object.keys(def);
        if (defAttributeNames.includes(COLUMN_KEY)) {
            value = def[COLUMN_KEY];
        } else if (defAttributeNames.includes(OPERATION_KEY)) {
            value = def[OPERATION_KEY];
        } else if (defAttributeNames.includes(VALUE_KEY)) {
            value = def[VALUE_KEY];
        }
    }
    return value;
};

//returns arity if it's not defined returns min arity and it it's not defined returns 0
const getOperationArityOrMinArity = (operationName, operations) => {
    let arity = 0;
    if (operations != null) {
        const filteredOperations = operations.filter(operation => operation.name == operationName);
        const operation = filteredOperations[0];
        if (operation != null && !Number.isNaN(Number(operation.arity))) {
            arity = operation.arity;
        } else if (operation != null && !Number.isNaN(Number(operation.min_arity))) {
            arity = operation.min_arity;
        }
    }
    return arity;
};

//returns min arity or null if it's not defined
const getOperationMinArity = (operationName, operations) => {
    let minArity = null;
    if (operations != null) {
        const filteredOperations = operations.filter(operation => operation.name == operationName);
        const operation = filteredOperations[0];
        if (operation != null && !Number.isNaN(Number(operation.min_arity))) {
            minArity = operation.min_arity;
        }
    }
    return minArity;
};